/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// 0.5 seconds
const INTERVAL = 500;

/**
 * Debounce a function.
 * @param func the function to debounce
 * @param timeout the timeout in milliseconds
 * @returns the debounced function
 */
export function debounce(func: (...args: unknown[]) => void, timeout = 300) {
  let timer: NodeJS.Timeout;
  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(args);
    }, timeout);
  };
}

/**
 * The storage state will be mirrored in the local storage.
 */
export interface StorageState {
  lastUpdated?: number;
  data: {
    latestProjects: {
      id: string;
      weight: number;
    }[];
  };
}

/**
 * The initial state of the storage.
 */
const emptyState: StorageState = {
  lastUpdated: undefined,
  data: {
    latestProjects: [],
  },
};

/**
 * Load the storage state from the local storage.
 */
const initialState: StorageState = {
  data: Object.keys(emptyState.data).reduce((acc, key) => {
    const value = localStorage.getItem(key as keyof StorageState['data']);
    if (value) {
      acc[key as keyof StorageState['data']] = JSON.parse(value);
    }
    return acc;
  }, emptyState.data),
};

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    loadStorage: (state) => {
      Object.keys(emptyState.data).forEach((key) => {
        const value = localStorage.getItem(key as keyof StorageState['data']);
        return value
          ? {
            ...state,
            data: {
              ...state.data,
              [key as keyof StorageState['data']]: JSON.parse(value),
            },
          }
          : state;
      });
    },
    setStorage: (
      state,
      action: PayloadAction<{
        key: keyof StorageState['data'];
        value: StorageState['data'][keyof StorageState['data']];
      }>,
    ) => {
      const now = Date.now();
      const callback = () => {
        localStorage.setItem(action.payload.key, JSON.stringify(action.payload.value));
      };

      if (state.lastUpdated && now - state.lastUpdated < INTERVAL) {
        debounce(callback, INTERVAL)();
      } else {
        callback();
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
        lastUpdated: now,
      };
    },
    clearStorage: () => {
      localStorage.clear();
      return emptyState;
    },
  },
});

export const { loadStorage, setStorage, clearStorage } = storageSlice.actions;

export default storageSlice.reducer;
