import {
  Alert,
  Avatar,
  Box,
  Button,
  ColorPaletteProp,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  InputProps,
  Stack,
  Typography,
} from '@mui/joy';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import KanopApi from 'api/openapi/KanopApi';
import logo from 'assets/branding/kanop-500.png';
import errors from 'assets/errors.json';
import React, { useCallback, useEffect, useState } from 'react';
import { BiKey, BiUser } from 'react-icons/bi';
import { PiPresentationLight } from 'react-icons/pi';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Title from 'utils/Title';
import Prompt from './Prompt';

/**
 * A function that retrieves the error message for a given error status string.
 * @param errKey The error status string (e.g. 'error.email.unknown').
 * @param domTarget The DOM element to which the error message will be added.
 * @returns {string} The error message.
 */
export function handleError(errKey: string, domTarget: string): string | undefined {
  const keys = Object.keys(errors);
  if (keys.includes(errKey)) {
    const key = errKey as keyof typeof errors;
    return errors[key].target === domTarget ? errors[key].dialog : '';
  }
  return domTarget === 'form' ? 'Internal server error. Please try again later.' : undefined;
}

/**
 * A function that returns a TextField with an automatically generated error message
 * when the TextField is in an error state.
 * @param props The TextField props.
 * @returns {JSX.Element} The TextField.
 */
export function HelperTextField(
  {
    type,
    error,
    ...other
  }: Omit<InputProps, 'error'> & { type: string; error: FetchBaseQueryError | SerializedError | undefined },
): JSX.Element {
  const errorDetail = error && ('data' in error) ? (error.data as Record<string, string>).detail : undefined;
  const message = errorDetail ? handleError(errorDetail, `field.${type}`) : undefined;

  return (
    <FormControl>
      <Input
        type={type}
        error={Boolean(message)}
        {...other}
      />
      <FormHelperText>
        <Typography color="danger" fontSize="inherit">
          {message}
        </Typography>
      </FormHelperText>
    </FormControl>
  );
}

export function FormError({
  error,
  color = 'danger',
}: {
  error: FetchBaseQueryError | SerializedError | undefined;
  color?: ColorPaletteProp;
}): JSX.Element | null {
  const errorDetail = error && ('data' in error) ? (error.data as Record<string, string>).detail : undefined;
  const message = errorDetail ? handleError(errorDetail, 'form') : undefined;
  return message ? (
    <Alert color={color} variant="outlined">
      {message}
    </Alert>
  ) : null;
}

/**
 * A page containing a form to login to the application.
 * @returns {JSX.Element} The login page.
 */
export default function Login(): JSX.Element {
  const [login, {
    isLoading: loading,
    error,
    isSuccess,
  }] = KanopApi.useLoginLoginPostMutation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  /**
   * A function that handles the submission of the login form.
   * @param event The event that triggered the submission.
   * @returns {Promise<void>} A promise that resolves when the login is complete.
   */
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      return login({
        bodyLoginLoginPost: {
          email,
          password,
        },
      });
    },
    [email, login, password],
  );

  useEffect(() => {
    if (isSuccess) {
      navigate(searchParams.get('next') || '/dashboard');
    }
  }, [isSuccess, navigate, searchParams]);

  return (
    <Prompt>
      <Title text="Kanop - Login" />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: 4,
        }}
      >
        <img
          src={logo}
          alt="kanop"
          style={{ height: '80px', width: '80px', borderRadius: '6px' }}
        />
        <Typography level="h3">Login</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'left',
            width: '100%',
            gap: 2,
          }}
        >
          <HelperTextField
            type="email"
            placeholder="Email"
            autoComplete="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            fullWidth
            startDecorator={<BiUser />}
            error={error}
          />
          <Stack width="100%" gap={1}>
            <HelperTextField
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
              fullWidth
              startDecorator={<BiKey />}
              error={error}
            />
            <Typography
              level="body-xs"
              component={Link}
              to="/password/lost"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Forgot Password?
            </Typography>
          </Stack>
        </Box>
        <FormError error={error} />
        <Stack gap={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
              gap: 3,
            }}
          >
            <Button component={Link} to="/register" color="neutral" variant="plain">
              Register
            </Button>
            <Button type="submit" color="primary" loading={loading}>
              Sign In
            </Button>
          </Box>
          <Divider>
            <Typography level="body-xs">
              Need a showcase?
            </Typography>
          </Divider>
          <Button
            size="lg"
            variant="plain"
            color="neutral"
            startDecorator={(
              <Avatar size="lg">
                <PiPresentationLight />
              </Avatar>
          )}
            onClick={() => {
              const credentials = {
                email: 'demo@kanop.io',
                password: 'Welcome2K',
              };

              login({
                bodyLoginLoginPost: credentials,
              });
            }}
            sx={{
              margin: -3,
              marginTop: -1,
              paddingX: 2,
              paddingY: 1,
              gap: 1,
            }}
          >
            <Stack>
              <Typography level="body-sm" fontWeight="md" textAlign="left">
                Try the demo
              </Typography>
              <Typography level="body-xs" fontWeight="sm" textAlign="left">
                Explore sample projects and data without creating an account
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </Box>
    </Prompt>
  );
}
