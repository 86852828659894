import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { store } from 'store/Store';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

TagManager.initialize({
  gtmId: 'GTM-WR2PTFD',
  dataLayerName: 'dataLayer',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <App />
    {/* Toast Container allowing a toast notification to be displayed on highup components */}
    <ToastContainer
      position="top-center"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Bounce}
      style={{ width: '500px' }}
      limit={4} // number of toasts that can be displayed at once on the screen
    />
  </Provider>,
);
