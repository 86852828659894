import { Box, CircularProgress, LinearProgress } from '@mui/joy';
import KanopApi from 'api/openapi/KanopApi';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from 'store/Store';
import NotFound from 'pages/default/NotFound';

export enum Privilege {
  READER = 0,
  CUSTOMER = 1,
  KANOP_ADMINISTRATOR = 2,
  KANOP_DEVELOPER = 3,
}

export interface PrivilegeGroup {
  name?: string;
  privileges: Privilege[];
}

export enum Strategy {
  REDIRECT = 'redirect',
  RENDER = 'render',
  HIDE = 'hide',
  NOT_FOUND = 'not_found',
}

export interface RestrictionProps {
  children: React.ReactNode;
  /**
   * The privilege level required to access the wrapped component.
   * @default Privilege.USER
   * @type {Privilege}
   * @memberof RestrictionProps
   * @see Privilege
   */
  privilege?: Privilege | PrivilegeGroup;
  /**
   * Whether the user must have the exact privilege level or a higher one.
   * @default false
   * @type {boolean}
   * @memberof RestrictionProps
   * @see Privilege
   */
  exclusive?: boolean;
  /**
   * The strategy to use when the user does not have the required privilege.
   * @default Strategy.REDIRECT
   * @type {Strategy}
   * @memberof RestrictionProps
   * @see Strategy
   * @see Privilege
   */
  strategy?: Strategy;
  /**
   * The placeholder to be displayed when the user does not have the required privilege.
   * @default "Unauthorized"
   * @type {JSX.Element}
   * @memberof RestrictionProps
   * @see Strategy
   * @see Privilege
   * @remarks This prop is only used when the strategy is set to Strategy.RENDER.
   */
  placeholder?: JSX.Element;
  /**
   * The type of loader to display when the user is being checked for authorization.
   * @default "circular"
   * @type {('circular' | 'linear' | 'none')}
   * @memberof RestrictionProps
   * @see Strategy
   * @see Privilege
   */
  loader?: 'circular' | 'linear' | 'none';
}

/**
 * A wrapper component that displays the children if the user is logged in,
 * otherwise redirects to the login page.
 */
function Restriction({
  children,
  privilege = 0,
  exclusive = false,
  strategy = Strategy.REDIRECT,
  placeholder = <>Unauthorized</>,
  loader = 'circular',
}: RestrictionProps): JSX.Element | null {
  const bearers = useSelector((state: RootState) => state.system.bearers);
  const { data: user } = KanopApi.useReadUsersMeMeGetQuery(undefined, {
    skip: !bearers,
  });

  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (bearers?.access) {
      const role = Privilege[(user?.role as keyof typeof Privilege) ?? 'READER'];
      if (typeof privilege === 'number') {
        if (exclusive) {
          setAuthorized(role === privilege);
        } else {
          setAuthorized(role >= privilege);
        }
      } else if (exclusive) {
        setAuthorized(privilege.privileges.includes(role));
      } else {
        setAuthorized(privilege.privileges.some((p) => role >= p));
      }
    } else {
      setAuthorized(false);
    }
    setLoading(false);
  }, [user, privilege, exclusive, bearers]);

  const loaderElement = useMemo(() => {
    switch (loader) {
      case 'circular':
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        );
      case 'linear':
        return <LinearProgress />;
      case 'none':
        return null;
      default:
        return null;
    }
  }, [loader]);

  const handlerElement = useMemo(() => {
    switch (strategy) {
      case Strategy.REDIRECT:
        return <Navigate to={`/login?next=${location.pathname + location.search}`} />;
      case Strategy.RENDER:
        return placeholder;
      case Strategy.HIDE:
        return null;
      case Strategy.NOT_FOUND:
        return <NotFound />;
      default:
        return null;
    }
  }, [strategy, location.pathname, location.search, placeholder]);

  if (loading) {
    return loaderElement;
  }

  if (authorized) {
    return children as JSX.Element;
  }

  return handlerElement;
}

export default Restriction;
