class CustomControlContainer {
  controlContainer: HTMLDivElement;

  _map: any;

  constructor(controlContainer: any) {
    this.controlContainer = controlContainer;
  }

  onAdd(map: any) {
    this._map = map;
    return this.controlContainer;
  }

  onRemove() {
    if (this.controlContainer.parentNode) {
      this.controlContainer.parentNode.removeChild(this.controlContainer);
    }
    this._map = undefined;
  }
}

export default CustomControlContainer;
