import {
  Typography, Table, Stack, Box,
  Button,
  Tooltip,
} from '@mui/joy';
import React from 'react';
import mapboxgl, { LngLatBoundsLike, LngLatLike } from 'mapbox-gl';
import {
  FeatureCollectionPlotGroundTruth,
  LandCoverClassDetail,
  PlotGroundTruth,
  PlotProperties,
  PlotsResponse,
} from 'api/openapi/generated/RawApi';
import { IoTrash } from 'react-icons/io5';
import KanopApi from 'api/openapi/KanopApi';
import { useParams } from 'react-router-dom';
import ProceduralDialog from 'utils/ProceduralDialog';
import handleError from 'utils/ErrorHandler';
import { BsEye } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';

const PlotList = ({
  allFeatures,
  map,
  setOpen,
  refetch,
  uploadData,
  uploadError,
  plotsData,
  setproperties,
  setOpenFormPopup,
  setViewSinglePlot,
  removeMarker,
  popUptitle,
  collectionLocked,
  addLayerAndSource,
  removeLayerAndSource,
  draw,
}: {
      allFeatures: FeatureCollectionPlotGroundTruth[],
      map: mapboxgl.Map | null,
      plotsData?: PlotsResponse | undefined,
      setOpen: (open: boolean) => void,
      uploadData?: boolean,
      refetch?: () => void,
      uploadError?: boolean,
      collectionLocked: boolean,
      popUptitle: string,
      removeLayerAndSource: (map: mapboxgl.Map) => void,
      draw: MapboxDraw | null,
      setViewSinglePlot: (open: boolean) => void,
      addLayerAndSource: (map: mapboxgl.Map,
      layerId: string,
      sourceId: string,
      features: PlotGroundTruth,
      geometry: GeoJSON.Geometry,
        ) => void,
      setOpenFormPopup: (open: boolean) => void,
      setproperties: (properties: PlotProperties) => void,
      removeMarker: (id: string) => void,
    }) => {
  const { collectionId } = useParams<{ collectionId: string }>();

  const [trigger,
    {
      isLoading,
    }] = KanopApi.useLazyGetPlotByIdCollectionsCollectionIdPlotsPlotIdGetQuery();

  /* function to handle the pan animation and display of
  colored plot on selected plots from View Data table
  */
  const handleButtonClick = (featureState: PlotProperties) => {
    setViewSinglePlot(true);
    setOpenFormPopup(false);
    trigger({ collectionId: collectionId as string, plotId: featureState.plotId as string })
      .unwrap().then((data) => {
        if (!map) {
          return;
        }

        data.plot.features.forEach((feature) => {
          const { geometry } = feature;

          draw?.add({
            type: 'Feature',
            properties: feature.properties,
            geometry: geometry as GeoJSON.Geometry,
          });
          // Add the feature as a new source
          const sourceId = `plot-${feature.properties.plotId}`;
          const layerId = `plot-${feature.properties.plotId}`;

          addLayerAndSource(map, layerId, sourceId, feature, geometry as GeoJSON.Geometry);

          setproperties(feature.properties);

          setOpen(false);
          if (geometry.type === 'Polygon') {
            const coordinates = geometry.coordinates[0];
            const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

            // Extend the bounds to include all points in the outer boundary
            coordinates.forEach((coord: LngLatLike | LngLatBoundsLike) => {
              bounds.extend(coord);
            });

            // Fit the map to the bounds
            map.fitBounds(bounds, { padding: 20 });
          }
        });
      });
  };

  // fetch the existing land cover classes
  const {
    data,
  } = KanopApi.useListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetQuery({
    collectionId: collectionId as string,
  });

  const [deletePlot,
  ] = KanopApi.useDeletePlotsCollectionsCollectionIdPlotsDeleteMutation();

  const handlePlotDelete = async (plotId: string) => {
    if (!plotsData) return;
    deletePlot({ collectionId: collectionId as string, plotIds: [plotId] }).unwrap().then(() => {
      const getAllFeatures = draw?.getAll();

      // remove all the features from the map
      if (getAllFeatures) {
        getAllFeatures.features.forEach((feature) => {
          draw?.delete(feature.id as string);
        });
      }

      // pick out the deleted plot in the popup and delete on the map as well
      const deletedFeature = getAllFeatures?.features.find(
        (plot) => plot.properties?.plotId === plotId,
      );

      if (deletedFeature && deletedFeature.id) {
        // loop draw features and delete the deleted plot
        draw?.getAll().features.forEach((feature) => {
          if (feature.id === deletedFeature.id) {
            draw?.delete(feature.id as string);
          }
        });
      }

      // remove the layers and sources of deleted plot
      if (map) {
        const sourceId = `plot-${plotId}`;
        const layerId = `plot-${plotId}`;

        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }

        if (map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      }
      removeMarker(deletedFeature?.properties?.plotReference);
      refetch?.();
      setOpenFormPopup(false);
    }).catch((err) => {
      handleError(err);
    });
  };

  return (
    <Stack
      sx={{
        overflow: 'auto',
        maxHeight: '70vh',
      }}
    >
      <Stack
        direction="row"
        alignContent="center"
        marginY="auto"
        marginBottom={2}
        justifyContent="space-between"
      >
        <Typography
          level="body-lg"
          fontWeight="lg"
        >
          {popUptitle}
        </Typography>
      </Stack>
      <Table
        borderAxis="bothBetween"
        hoverRow
      >
        {(
          (plotsData && plotsData.plots.length > 0)
           || (allFeatures && allFeatures.length > 0)) && (
           <thead>
             <tr>
               <td>Plot reference</td>
               <td>Observation date</td>
               <td>Land cover</td>
               <td>Tree height mean (m)</td>
               <td>Canopy height mean (m)</td>
               <td>Canopy cover (%)</td>
               <td>Aboveground biomass (t / ha)</td>
               <td>Soil organic carbon stock 30cm (tc / ha)</td>
             </tr>
           </thead>
        )}
        {plotsData && plotsData.plots.length > 0 && (
        // Sort the plots alphabetically by plotReference, ignoring case and trimming whitespace
          [...plotsData.plots]
            .sort((a, b) => String(a.plotReference).trim().localeCompare(String(b.plotReference).trim(), undefined, { sensitivity: 'base' })).map((featureState) => {
              const {
                plotReference,
                observationDate,
                canopyCover,
                canopyHeightMean,
                treeHeightMean,
                agbValue,
                landCoverClassId,
                soilOrganicCarbon30cm,
                plotId,
              } = featureState;
              return (
                <tbody>
                  <tr>
                    <td>
                      <Typography color="primary">{plotReference}</Typography>
                    </td>
                    <td>{observationDate}</td>
                    <td>
                      {/* find the corresponding landcover label with the available LC classId */}
                      {data?.classes.find(
                        (
                          landCoverClass: LandCoverClassDetail,
                        ) => landCoverClass.classId === landCoverClassId,
                      )?.label || '---'}
                    </td>
                    <td>{treeHeightMean || '---'}</td>
                    <td>{canopyHeightMean || '---'}</td>
                    <td>{canopyCover || '---'}</td>
                    <td>{agbValue || '---'}</td>
                    <td>{soilOrganicCarbon30cm || '---'}</td>
                    {popUptitle === 'Ground Truth Plots' && (
                    <td>
                      <Tooltip size="sm" title="View this plot" color="primary" variant="plain">
                        <Button
                          variant="plain"
                          color="primary"
                          sx={{
                            padding: 1,
                            // remove background color on hover
                            '&:hover': {
                              backgroundColor: 'transparent',
                              color: 'grey',
                            },
                          }}
                          onClick={() => handleButtonClick(featureState)}
                        >
                          <BsEye />
                        </Button>
                      </Tooltip>
                      {!collectionLocked && (
                      <>
                        <Tooltip size="sm" title="Edit this plot" color="neutral" variant="plain">
                          <Button
                            variant="plain"
                            color="primary"
                            onClick={() => {
                              handleButtonClick(featureState);
                              setOpenFormPopup(true);
                            }}
                            sx={{
                              padding: 1,
                              '&:hover': {
                                backgroundColor: 'transparent',
                                color: 'grey',
                              },
                            }}
                          >
                            <BiEditAlt />
                          </Button>
                        </Tooltip>
                        <Tooltip size="sm" title="Delete this plot" color="danger" variant="plain">
                          <Button
                            variant="plain"
                            color="danger"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent triggering the row click event
                              handlePlotDelete(plotId ?? '');
                            }}
                            sx={{
                              padding: 1,
                              '&:hover': {
                                backgroundColor: 'transparent',
                                color: 'grey',
                              },
                            }}
                          >
                            <IoTrash />
                          </Button>
                        </Tooltip>
                      </>
                      )}
                    </td>
                    )}
                  </tr>
                </tbody>
              );
            }))}
        {isLoading && <Button loading variant="plain" />}
      </Table>
      {plotsData && plotsData.plots.length === 0 && allFeatures.length === 0 && (
        <Stack>
          <Stack
            justifyContent="center"
            alignItems="center"
            width="100%"
            marginX="auto"
          >
            <Typography
              width="auto"
              display="flex"
              justifyContent="center"
              marginX="auto"
              level="body-sm"
              textAlign="center"
            >
              {ProceduralDialog.generate('EmptyPlots')}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Box margin="auto" marginTop={3}>
        {uploadData && <Typography variant="plain" color="success">Upload successful</Typography>}
        {uploadError && <Typography variant="plain" color="danger">There was an error during upload, please contact support@kanop.io</Typography>}
      </Box>
    </Stack>
  );
};

export default PlotList;
