export default class CustomButtonControl {
  draw: MapboxDraw | null;

  container: HTMLDivElement;

  map: mapboxgl.Map | undefined;

  constructor(draw: MapboxDraw) {
    this.draw = draw;
    this.container = document.createElement('div');
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    const button = document.createElement('button');
    button.className = 'mapbox-gl-draw_ctrl-draw-btn custom-button';
    button.type = 'button';
    button.title = 'Draw Circle';
    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="13" height="13 " fill="black"><circle cx="12" cy="12" r="10" stroke="black" stroke-width="4" fill="none"/></svg>';

    button.onclick = () => {
      this.draw?.changeMode('draw_circle');
    };

    this.container.appendChild(button);
    return this.container;
  }

  onRemove() {
    if (this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    this.map = undefined;
  }
}
