import React, { Suspense, lazy, useState } from 'react';
import {
  BrowserRouter, Navigate, Outlet, Route, Routes,
} from 'react-router-dom';

import { Box, CircularProgress, Stack } from '@mui/joy';

import Shield from 'components/wrappers/shield/Shield';
import JoinOrganization from 'pages/security/JoinOrganization';
import NewOrganization from 'pages/security/NewOrganization';
import Restriction, { Privilege, Strategy } from 'utils/security/Restriction';
import ThemeLoader from 'utils/theming/ThemeLoader';
import './App.global.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CookiesProvider } from 'react-cookie';
import GTmap from 'pages/ground truth/GTMap';

const Navbar = lazy(() => import('components/wrappers/Navbar'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const NotFound = lazy(() => import('pages/default/NotFound'));
const ProjectCreation = lazy(() => import('pages/projects/builder/ProjectBuilder'));
const ProjectDashboard = lazy(() => import('pages/projects/dashboard/ProjectDashboard'));
const ProjectSettings = lazy(() => import('pages/projects/settings/ProjectSettings'));
const EmailConfirmed = lazy(() => import('pages/security/EmailConfirmed'));
const EmailSent = lazy(() => import('pages/security/EmailSent'));
const Login = lazy(() => import('pages/security/Login'));
const Logout = lazy(() => import('pages/security/Logout'));
const PasswordLost = lazy(() => import('pages/security/PasswordLost'));
const PasswordReset = lazy(() => import('pages/security/PasswordReset'));
const Register = lazy(() => import('pages/security/Register'));
const UserProfile = lazy(() => import('pages/profiles/UserProfile'));
const OrganizationProfile = lazy(() => import('pages/profiles/OrganizationProfile'));
const Settings = lazy(() => import('pages/settings/Settings'));
const ProjectMap = lazy(() => import('components/projects/map/ProjectMap'));

/**
 * The main application component.
 * @returns {JSX.Element}
 */
export default function App(): JSX.Element {
  const [tabToDisplay, setTabToDisplay] = useState<string>('Projects');

  return (
    <CookiesProvider>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          margin: 0,
          padding: 0,
        }}
      >
        <ThemeLoader>
          <BrowserRouter>
            <Routes>
              <Route
                element={(
                  <Suspense
                    fallback={(
                      <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                        <CircularProgress />
                      </Stack>
                    )}
                  >
                    <Shield status={/^5\d{2}$/}>
                      <Outlet />
                    </Shield>
                  </Suspense>
                )}
              >
                <Route index element={<Navigate to="/dashboard" />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="register">
                  <Route index element={<Register />} />
                  <Route path="pending" element={<EmailSent />} />
                  <Route path="confirmed" element={<EmailConfirmed />} />
                </Route>
                <Route path="password">
                  <Route index element={<Navigate to="lost" />} />
                  <Route path="lost" element={<PasswordLost />} />
                  <Route path="reset" element={<PasswordReset />} />
                </Route>

                <Route element={(
                  <Navbar
                    setCurrentTab={setTabToDisplay}
                  />
                )}
                >
                  <Route
                    path="dashboard"
                    element={(
                      <Restriction>
                        <Outlet />
                      </Restriction>
                    )}
                  >
                    <Route index element={<Navigate to="overview" />} />
                    <Route path="overview" element={<Dashboard currentTab={tabToDisplay} setCurrentTab={setTabToDisplay} />} />
                  </Route>
                  <Route
                    path="projects"
                    element={(
                      <Restriction>
                        <Outlet />
                      </Restriction>
                    )}
                  >
                    <Route index element={<NotFound />} />
                    <Route path=":projectId">
                      <Route index element={<ProjectDashboard />} />
                      <Route path="settings" element={<ProjectSettings />} />
                      <Route path="map" element={<ProjectMap />} />
                    </Route>
                    <Route path="new" element={<ProjectCreation />} />
                  </Route>
                  <Route
                    element={(
                      <Restriction>
                        <Outlet />
                      </Restriction>
                    )}
                  >
                    <Route path="settings" element={<Settings />} />
                  </Route>
                  {/* routes for each map collection */}
                  <Route
                    path="collection"
                    element={(
                      <Restriction
                        privilege={Privilege.KANOP_ADMINISTRATOR}
                        exclusive={false} // Means KANOP_ADMINISTRATOR or any role higher can access
                        strategy={Strategy.NOT_FOUND} // Show a Not Found page if unauthorized
                      >
                        <Outlet />
                      </Restriction>
                    )}
                  >
                    <Route index element={<NotFound />} />
                    <Route path=":collectionId">
                      <Route index element={<GTmap />} />
                    </Route>
                  </Route>
                </Route>

                <Route element={<Navbar noDisplace noBlur />}>
                  <Route
                    path="users"
                    element={(
                      <Restriction>
                        <Outlet />
                      </Restriction>
                    )}
                  >
                    <Route path="me" element={<UserProfile />} />
                  </Route>
                  <Route
                    path="organizations"
                    element={(
                      <Restriction>
                        <Outlet />
                      </Restriction>
                    )}
                  >
                    <Route path=":organizationId" element={<OrganizationProfile />} />
                    <Route path="new" element={<NewOrganization />} />
                    <Route path="join/:organizationId" element={<JoinOrganization />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeLoader>
      </Box>
    </CookiesProvider>
  );
}
