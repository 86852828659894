import { extendTheme } from '@mui/joy/styles';

/**
 * Theme generator for the JoyUI CssVarsProvider.
 */

// previous theme object to maintain Kanop's current theme prior to MUI update
// previous theme object to maintain Kanop's current theme prior to MUI update
const warning = {
  50: '#FFF8C5',
  100: '#FAE17D',
  200: '#EAC54F',
  300: '#D4A72C',
  400: '#BF8700',
  500: '#9A6700',
  600: '#7D4E00',
  700: '#633C01',
  800: '#4D2D00',
  900: '#3B2300',
};

const success = {
  50: '#F3FEF5',
  100: '#D7F5DD',
  200: '#77EC95',
  300: '#4CC76E',
  400: '#2CA24D',
  500: '#1A7D36',
  600: '#0F5D26',
  700: '#034318',
  800: '#002F0F',
  900: '#001D09',
};

const danger = {
  50: '#FFF8F6',
  100: '#FFE9E8',
  200: '#FFC7C5',
  300: '#FF9192',
  400: '#FA5255',
  500: '#D3232F',
  600: '#A10E25',
  700: '#77061B',
  800: '#580013',
  900: '#39000D',
};

const info = {
  50: '#FDF7FF',
  100: '#F4EAFF',
  200: '#E1CBFF',
  300: '#C69EFF',
  400: '#A374F9',
  500: '#814DDE',
  600: '#5F35AE',
  700: '#452382',
  800: '#301761',
  900: '#1D0A42',
};

const neutral = {
  50: '#F7F7F8',
  100: '#EBEBEF',
  200: '#D8D8DF',
  300: '#B9B9C6',
  400: '#8F8FA3',
  500: '#73738C',
  600: '#5A5A72',
  700: '#434356',
  800: '#25252D',
  900: '#131318',
};

const uiTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#dff0ee',
          100: '#b1dad5',
          200: '#7fc3ba',
          300: '#4fab9e',
          400: '#1b8878',
          500: '#1b8878',
          600: '#197b6c',
          700: '#166c5d',
          800: '#135c4f',
          900: '#0e4134',
        },
        common: {
          white: '#FFF',
          black: '#09090D',
        },
        success,
        danger,
        info,
        warning,
        neutral,
        background: {
          body: 'var(--joy-palette-neutral-50)',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: '#dff0ee',
          100: '#b1dad5',
          200: '#7fc3ba',
          300: '#4fab9e',
          400: '#1b8878',
          500: '#1b8878',
          600: '#197b6c',
          700: '#166c5d',
          800: '#135c4f',
          900: '#0e4134',
        },
        success,
        danger,
        info,
        warning,
        neutral,
        background: {
          body: 'var(--joy-palette-common-black)',
          surface: 'var(--joy-palette-neutral-900)',
        },
      },
    },
  },

  // Maintaining old Tab style for UI consistency
  components: {
    JoyChip: {
      defaultProps: {
        variant: 'solid',
        color: 'primary',
      },
    },
    JoyChipDelete: {
      defaultProps: {
        variant: 'solid',
        color: 'primary',
      },
    },
    JoyIconButton: {
      defaultProps: {
        variant: 'soft',
        color: 'primary',
      },
    },
    JoyTabList: {
      defaultProps: {
        variant: 'soft',
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          gap: '0.25rem',
          padding: '0.25rem',
          '--List-padding': '0.25rem',
          borderRadius: 'var(--joy-radius-lg)',
          '--List-radius': 'var(--joy-radius-lg)',
        },
      },
    },
    JoyTab: {
      defaultProps: {
        disableIndicator: true,
      },
      styleOverrides: {
        root: {
          width: '100%',
          '&[aria-selected="true"]': {
            boxShadow: 'var(--joy-shadow-sm)',
          },
        },
      },
    },
  },
  shadow: {
    xs: `var(--joy-shadowRing, 0 0 #000),
        0 1px 2px 0 rgba(var(--joy-shadowChannel, 187 187 187) / 0.12)`,
    sm: `var(--joy-shadowRing, 0 0 #000),
        0.3px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11),
        0.5px 1.3px 1.8px -0.6px rgba(var(--joy-shadowChannel, 187 187 187) / 0.18),
        1.1px 2.7px 3.8px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.26)`,
    md: `var(--joy-shadowRing, 0 0 #000),
        0.3px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.12),
        1.1px 2.8px 3.9px -0.4px rgba(var(--joy-shadowChannel, 187 187 187) / 0.17),
        2.4px 6.1px 8.6px -0.8px rgba(var(--joy-shadowChannel, 187 187 187) / 0.23),
        5.3px 13.3px 18.8px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.29)`,
    lg: `var(--joy-shadowRing, 0 0 #000),
        0.3px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11),
        1.8px 4.5px 6.4px -0.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.13),
        3.2px 7.9px 11.2px -0.4px rgba(var(--joy-shadowChannel, 187 187 187) / 0.16),
        4.8px 12px 17px -0.5px rgba(var(--joy-shadowChannel, 187 187 187) / 0.19),
        7px 17.5px 24.7px -0.7px rgba(var(--joy-shadowChannel, 187 187 187) / 0.21)`,
    xl: `var(--joy-shadowRing, 0 0 #000),
        0.3px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11), 
        1.8px 4.5px 6.4px -0.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.13), 
        3.2px 7.9px 11.2px -0.4px rgba(var(--joy-shadowChannel, 187 187 187) / 0.16), 
        4.8px 12px 17px -0.5px rgba(var(--joy-shadowChannel, 187 187 187) / 0.19), 
        7px 17.5px 24.7px -0.7px rgba(var(--joy-shadowChannel, 187 187 187) / 0.21), 
        10.2px 25.5px 36px -0.9px rgba(var(--joy-shadowChannel, 187 187 187) / 0.24), 
        14.8px 36.8px 52.1px -1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.27), 21px 52.3px 74px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.29)`,
  },
});

export default uiTheme;
