import {
  Button,
  CircularProgress,
  Divider,
  Stack, Typography,
} from '@mui/joy';
import KanopApi from 'api/openapi/KanopApi';
import React from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Title from 'utils/Title';
import Prompt from './Prompt';

export default function JoinOrganization() {
  const [searchParams] = useSearchParams();
  const { organizationId } = useParams<{organizationId: string}>();
  const inviteeUuid = searchParams.get('inviteeUuid');

  const [joinOrganization, {
    isLoading: isJoining,
    error: joinError,
  }] = KanopApi.useAcceptInvitationOrganizationsOrganizationIdAcceptPatchMutation();

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    error: organizationError,
  } = KanopApi.useGetOrganizationByIdOrganizationsOrganizationIdGetQuery({
    organizationId: organizationId as string,
  }, {
    skip: organizationId === undefined || organizationId === '',
  });

  const { data: user } = KanopApi.useReadUsersMeMeGetQuery();

  return (
    <Prompt>
      {organization && organizationId && inviteeUuid && (
        <>
          <Title text={`Join ${organization.label}`} />
          <Stack
            gap={2}
            component="form"
            onSubmit={(event) => {
              event.preventDefault();
              joinOrganization({
                organizationId,
                inviteeUuid,
              });
            }}
          >
            <Typography level="h4">
              Hi
              {' '}
              {user?.firstName}
              ,
            </Typography>
            <Typography level="body-md">
              You&apos;ve been invited to join
              {' '}
              <strong>{organization.label}</strong>
              {' '}
              on Kanop.
            </Typography>

            <Typography level="body-xs">
              If you don&apos;t recognize this invitation, you can simply decline it.
            </Typography>
            <Divider />
            <Stack gap={0.5}>
              <Button
                component={Link}
                to="/"
                variant="plain"
                color="danger"
              >
                Decline
              </Button>
              <Button
                type="submit"
                variant="soft"
                loading={isJoining}
              >
                I&apos;m in!
              </Button>
            </Stack>
            {joinError && (
              <Typography level="body-xs" color="danger">
                Something went wrong.
              </Typography>
            )}
          </Stack>
        </>
      )}
      {isOrganizationLoading && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {(organizationError || !(organizationId && inviteeUuid)) && (
      <Stack gap={1}>
        <Typography level="h4">
          Oops!
        </Typography>
        <Typography level="body-md">
          This invitation is invalid.
        </Typography>
        <Typography level="body-xs">
          If you think this is a mistake, please contact
          {' '}
          <a href="mailto:support@kanop.io">
            Kanop support
          </a>
          .
        </Typography>
      </Stack>
      )}
    </Prompt>
  );
}
