/**
 * This code extends the Mapbox Draw library to enable drawing circle-like polygons on a map.
 *
 * Key Features:
 * - **RadiusMode**: A custom mode for Mapbox Draw that allows users to draw circles
 *  by specifying a center point and radius.
 * ----------------------------------------------------------------------------------------------
 * - **createVertex**: Creates a GeoJSON Point feature representing a vertex
 *  with metadata like the parent feature ID and whether it's active.
 *---------------------------------------------------------------------------------------------
 * - **createGeoJSONCircle**: Generates a GeoJSON Polygon approximating a circle
 *  using a series of points around a center coordinate.
 *---------------------------------------------------------------------------------------------
 * - **getDisplayMeasurements**: Calculates the length of a drawn feature and formats
 *  it for display in both metric (meters/kilometers) and standard (feet/miles) units.
 *---------------------------------------------------------------------------------------------
 * - **doubleClickZoom**: Ensures double-click zoom is enabled on the map if it was
 *  previously disabled.
 *---------------------------------------------------------------------------------------------
 * - **ensureRadiusSpan**: Ensures a display element for showing the current radius
 * of the circle exists in the DOM, creating and styling it if necessary.
 * ---------------------------------------------------------------------------------------------
 * - **removeRadiusSpan**: Removes the radius display element from the DOM when
 *  it's no longer needed.
 * ---------------------------------------------------------------------------------------------
 * - **RadiusMode Event Handlers**:
 *   - **onSetup**: Initializes the RadiusMode by setting up a new circle feature.
 *   - **clickAnywhere**: Handles clicks on the map during drawing,
 *  updating coordinates and ending the drawing if necessary.
 *   - **onMouseOut**: Removes the radius display element when the drawing mode is exited.
 *   - **onMouseMove**: Updates the radius display element with the current
 *  radius as the user moves the mouse.
 *   - **onStop**: Finalizes the drawing by creating a GeoJSON feature,
 *  triggering a `draw.create` event, and enabling double-click zoom if disabled.
 *   - **toDisplayFeatures**: Updates the display for the drawn circle,
 *  including showing a vertex point and measurement information.
 *
 * This functionality is used in the Ground Truth tool to allow users to draw
 * circle areas on a map,
 */

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import numeral from 'numeral';
import _ from 'lodash';
import lineDistance from '@turf/length';
import {
  Feature,
  GeoJsonProperties,
  FeatureCollection,
  Point, MultiPoint,
  LineString,
  MultiLineString,
  Polygon, MultiPolygon,
  GeometryCollection,
  Geometry,
} from 'geojson';
import { PlotGroundTruth } from 'api/openapi/generated/RawApi';

// This is a custom mode for Mapbox Draw that allows users to draw a circle-like polygon
const RadiusMode = _.extend({}, MapboxDraw.modes.draw_line_string);

// creates a vertex for the current pointer position
function createVertex(parentId: string, coordinates: number[], path: string, selected: boolean) {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: selected ? 'true' : 'false',
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  };
}

/* This function generates a GeoJSON representation of a circle-like polygon.
This polygon is approximated using a series of points around a circle with a specified radius,
which is useful for visualizing circular areas on a map */

// create a circle-like polygon given a center point and radius
// My reference https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js/39006388#39006388
function createGeoJSONCircle(center: number[], radiusMeter: number, parentId: string, points = 64) {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const meters = radiusMeter;

  const numberofMeterssPerLatDegree = 110.574 * 1000;
  const numberofMetersPerLongDegree = 111.32 * 1000;

  const ret = [];
  const distanceX = meters / (
    numberofMetersPerLongDegree * Math.cos((coords.latitude * Math.PI) / 180));

  const distanceY = meters / numberofMeterssPerLatDegree;

  // create loop to generate points around the circle
  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]); // Close the circle by repeating the first point

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [ret],
    },
    properties: {
      parent: parentId,
    },
  };
}

// a function to calculate and format measurements for a feature
/* This function computes the length of the drawn feature and formats it
  for display in both metric and standard units */
// `feature` - The feature to measure (could be a line, polygon, etc.)

/* switch the units from feet to miles when the length is greater than or equal
 to one mile (5280 feet).This conversion helps in displaying the distance in a
  more understandable unit for larger distances. */

function getDisplayMeasurements(
  feature: Feature<Geometry, GeoJsonProperties>
    | FeatureCollection<Geometry, GeoJsonProperties>
    | Point
     | MultiPoint
      | LineString
       | MultiLineString
        | Polygon
         | MultiPolygon
          | GeometryCollection<Geometry>,
) {
  // should log both metric and standard display strings for the current drawn feature

  // metric calculation
  // we calculate the length of the feature in meters
  const drawnLength = lineDistance(feature as Feature) * 1000; // meters

  let metricUnits = 'm';
  let metricFormat = '0,0';
  let metricMeasurement;

  let standardUnits = 'feet';
  let standardFormat = '0,0';
  let standardMeasurement;

  const meterToFeet = 3.28084;
  const feetToMiles = 5280;

  metricMeasurement = drawnLength;
  // If the drawn length is 1000 meters or more, convert to kilometers
  if (drawnLength >= 1000) {
    // if over 1000 meters, upgrade metric
    // we convert meters to kilometers if length is over 1000 meters
    metricMeasurement = drawnLength / 1000;
    metricUnits = 'm';
    metricFormat = '0.00';
  }

  // Convert drawn length from meters to feet (1 meter = 3.28084 feet)
  standardMeasurement = drawnLength * meterToFeet;
  if (standardMeasurement >= feetToMiles) {
    // if over 5280 feet, upgrade standard
    // Convert feet to miles (5280 feet = 1 mile)
    standardMeasurement /= feetToMiles;
    standardUnits = 'mi';
    standardFormat = '0.00';
  }

  const displayMeasurements = {
    metric: `${numeral(metricMeasurement).format(metricFormat)} ${metricUnits}`,
    standard: `${numeral(standardMeasurement).format(
      standardFormat,
    )} ${standardUnits}`,
  };

  return displayMeasurements;
}

const doubleClickZoom = {
  enable: (ctx: {
     map: { doubleClickZoom: {
         enable: () => void; }; }; _ctx: {
            store: { getInitialConfigValue: (arg0: string) => string; }; }; }) => {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map
        || !ctx.map.doubleClickZoom
        || !ctx._ctx
        || !ctx._ctx.store
        || !ctx._ctx.store.getInitialConfigValue
      ) return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
      ctx.map.doubleClickZoom.enable();
    }, 0);
  },
};
RadiusMode.onSetup = function (opts) {
  const props = (MapboxDraw as any).modes.draw_line_string.onSetup.call(this as any, opts);
  const circle = this.newFeature({
    type: 'Feature',
    properties: {
      meta: 'radius',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [[]],
    },
  });
  this.addFeature(circle);

  return {
    ...props,
    circle,
  };
};

(RadiusMode as any).clickAnywhere = function (state: {
        currentVertexPosition: number; line: {
             addCoordinate: (arg0: number,
                 arg1: any, arg2: any) => void; id: any; updateCoordinate: (
                    arg0: any, arg1: any, arg2: any) => void; };
                    direction: string; }, e: { lngLat: { lng: any; lat: any; }; }) {
  // this ends the drawing after the user creates a second point, triggering this.onStop
  if (state.currentVertexPosition === 1) {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    return this.changeMode('simple_select', { featureIds: [state.line.id] });
  }
  this.updateUIClasses({ mouse: 'add' });
  state.line.updateCoordinate(
    state.currentVertexPosition,
    e.lngLat.lng,
    e.lngLat.lat,
  );
  if (state.direction === 'forward') {
    state.currentVertexPosition += 1; // eslint-disable-line
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat,
    );
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }

  return null;
};

// this is the little box on the top center of the map that appears when drawing a radius
function ensureRadiusSpan() {
  let span = document.getElementById('radius-display');
  if (!span) {
    span = document.createElement('span');
    span.id = 'radius-display';
    span.style.position = 'fixed'; // Use 'fixed' to center relative to the viewport
    span.style.left = '50%'; // Center horizontally
    span.style.top = '100px'; // Center vertically
    span.style.transform = 'translate(0%, -50%)'; // Adjust position to truly center
    span.style.backgroundColor = 'white';
    span.style.padding = '5px';
    span.style.border = '1px solid #ccc';
    span.style.borderRadius = '3px';
    span.style.fontSize = '14px';
    span.style.color = '#000';
    span.style.fontFamily = 'Arial, sans-serif';
    span.style.zIndex = '1000'; // Ensure it is on top of other elements
    document.body.appendChild(span);
  }
  return span;
}

// a function to remove the radius span from the DOM when it's no longer needed
function removeRadiusSpan() {
  const span = document.getElementById('radius-display');
  if (span) {
    document.body.removeChild(span);
  }
}

RadiusMode.onKeyUp = function (state, e) {
  if (e.key === 'Escape') {
    removeRadiusSpan();
    this.changeMode('simple_select');
  }
};

// Event Handlers for the RadiusMode class to handle stopping the drawing
RadiusMode.onMouseOut = function (state, e) {
  // Remove the radius span when the drawing is stopped
  removeRadiusSpan();
};

RadiusMode.onMouseMove = function (state, e) {
  (MapboxDraw as any).modes.draw_line_string.onMouseMove.call(this, state, e);
  const geojson = state.line.toGeoJSON();
  const center = geojson.geometry.coordinates[0];
  const radiusMeter = lineDistance(geojson, { units: 'meters' });
  const circleFeature = createGeoJSONCircle(center, radiusMeter, state.line.id);
  (circleFeature as any).properties.meta = 'radius';
  state.circle.setCoordinates(circleFeature.geometry.coordinates);
  // Ensure the span element exists
  const radiusDisplayElement = ensureRadiusSpan();

  // Update the span element with the current radius
  radiusDisplayElement.innerHTML = `Radius: ${radiusMeter.toFixed(2)} m`;
};

// creates the final geojson point feature with a radius property
// triggers draw.create
RadiusMode.onStop = function (state) {
  doubleClickZoom.enable((this as any));

  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  // remove last added coordinate
  state.line.removeCoordinate('0');
  if (state.line.isValid()) {
    this.deleteFeature([state.line.id] as any, { silent: true });

    (this as any).map.fire('draw.create', {
      features: [state.circle.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id] as any, { silent: true });
    this.changeMode('simple_select', {}, { silent: true });
  }

  // Remove the radius span when the drawing is stopped
  removeRadiusSpan();
};

RadiusMode.toDisplayFeatures = function (state, geojson: any, display: any) {
  const isActiveLine = (geojson as any).properties.id === state.line.id;
  const modifiedGeojson = { ...geojson, properties: { ...geojson.properties, active: isActiveLine ? 'true' : 'false' } };
  if (!isActiveLine) return display(modifiedGeojson);

  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return null;
  modifiedGeojson.properties.meta = 'feature';

  // displays center vertex as a point feature
  display(
    createVertex(
      state.line.id,
      geojson.geometry.coordinates[
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      ],
      `${
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      }`,
      false,
    ),
  );

  // displays the line as it is drawn
  display(modifiedGeojson);

  const displayMeasurements = getDisplayMeasurements(geojson);

  // create custom feature for the current pointer position
  const currentVertex = {
    type: 'Feature',
    properties: {
      meta: 'currentPosition',
      radiusMetric: displayMeasurements.metric,
      radiusStandard: displayMeasurements.standard,
      parent: state.line.id,
    },
    geometry: {
      type: 'Point',
      coordinates: (geojson as PlotGroundTruth).geometry.coordinates[1],
    },
  };
  display(currentVertex as unknown as PlotGroundTruth);

  return null;
};

export default RadiusMode;
