import latex from 'assets/latex.json';

/**
 * A function that capitalizes the first letter of a string.
 * @param str The string to capitalize.
 * @returns {string} The capitalized string.
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * A function that converts a snake case / camel case string to a space-separated string, aka
 * a sentence.
 * @param str The string to convert.
 * @returns {string} The converted string.
 */
export function sentencify(str: string): string {
  return str.replaceAll(/([a-z])([A-Z])/g, '$1 $2')
    .replaceAll('_', ' ')
    .toLowerCase();
}

/**
 * A function that converts a snake case / camel case string to a space-separated string, aka
 * a sentence, and capitalizes the first letter, while also applying some custom
 * formatting rules.
 * @param str The string to convert.
 * @returns {string} The converted string.
 */
export function prettyPrint(str: string, useDelimiters = true, useLatex = false): string {
  let pretty = sentencify(str);
  Object.entries(latex).forEach(([key, value]) => {
    pretty = pretty.replaceAll(key, useLatex ? `${useDelimiters ? '$' : ''}${value.latex}${useDelimiters ? '$' : ''}` : value.string);
  });
  return capitalize(pretty);
}

/**
 * A function that converts a snake case string to a camel case string.
 * @param str The string to convert.
 * @returns {string} The converted string.
 */
export function snakeToCamel(str: string): string {
  return str.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
}

/**
 * Format number as 1 decimal over 10k and integer over 100k.
 * @param value number to format
 */
export function format1decimalOver10kAndIntegerOver100k(value: number): string {
  if (value >= 100000) return `${(value / 1000).toFixed(0)}k`;
  if (value >= 10000) return `${(value / 1000).toFixed(1)}k`;
  return value.toFixed().toString();
}

/**
 * A function that converts a CSS variable to a color. This was implemented to
 * convert Mui Joy's CSS variables to readable string colors.
 * @param cssVar The CSS variable to convert.
 * @returns {string} The color.
 */
export function cssVarToColor(cssVar: string): string {
  // css var format is: var(--alphanumeric-alphanumeric-alphanumeric..., hexOrWord)
  const regex = /var\(--.*?,\s*(.*?)\)/;
  const match = regex.exec(cssVar);
  if (match) {
    return match[1];
  }
  return '';
}

/**
 * A function that converts a string into its initials (e.g. 'co2eq_per_ha' -> 'cph').
 * @param str The string to convert.
 * @returns {string} The converted string.
 */
export function toInitials(str: string, separator = ' '): string {
  return str.split(separator).map((s) => s[0]).join('');
}
