// This theme was automatically generated from https://echarts.apache.org/en/theme-builder.html
import { registerTheme } from 'echarts';

/* istanbul ignore next */
export const textColors = {
  light: '#2f2f2f',
  dark: '#8b8b8b',
};

/* istanbul ignore next */
export const gradientColors = [
  '#313695',
  '#4575b4',
  '#74add1',
  '#abd9e9',
  '#e0f3f8',
  '#ffffbf',
  '#fee090',
  '#fdae61',
  '#f46d43',
  '#d73027',
  '#a50026',
];

/* istanbul ignore next */
export function register() {
  registerTheme('light', {
    color: [
      '#519163',
      '#69cc78',
      '#606f7f',
      '#89cbb3',
      '#235e21',
      '#6fa078',
    ],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#516b91',
      },
      subtextStyle: {
        color: '#93b7e3',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#edafda',
        color0: 'transparent',
        borderColor: '#d680bc',
        borderColor0: '#8fd3e8',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaa',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
      color: [
        '#519163',
        '#69cc78',
        '#606f7f',
        '#89cbb3',
        '#235e21',
        '#6fa078',
      ],
      label: {
        color: '#eee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#516b91',
        borderWidth: 0.5,
      },
      label: {
        color: '#000',
      },
      emphasis: {
        itemStyle: {
          areaColor: '#a5e7f0',
          borderColor: '#516b91',
          borderWidth: 1,
        },
        label: {
          color: '#516b91',
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#516b91',
        borderWidth: 0.5,
      },
      label: {
        color: '#000',
      },
      emphasis: {
        itemStyle: {
          areaColor: '#a5e7f0',
          borderColor: '#516b91',
          borderWidth: 1,
        },
        label: {
          color: '#516b91',
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [
            '#eeeeee',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [
            '#eeeeee',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [
            '#eeeeee',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: [
            '#eeeeee',
          ],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [
            'rgba(250,250,250,0.05)',
            'rgba(200,200,200,0.02)',
          ],
        },
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: '#999999',
      },
      emphasis: {
        iconStyle: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#8fd3e8',
        width: 1,
      },
      itemStyle: {
        color: '#8fd3e8',
        borderWidth: 1,
      },
      controlStyle: {
        color: '#8fd3e8',
        borderColor: '#8fd3e8',
        borderWidth: 0.5,
      },
      checkpointStyle: {
        color: '#8fd3e8',
        borderColor: '#8a7ca8',
      },
      label: {
        color: '#8fd3e8',
      },
      emphasis: {
        itemStyle: {
          color: '#8fd3e8',
        },
        controlStyle: {
          color: '#8fd3e8',
          borderColor: '#8fd3e8',
          borderWidth: 0.5,
        },
        label: {
          color: '#8fd3e8',
        },
      },
    },
    visualMap: {
      color: [
        '#516b91',
        '#59c4e6',
        '#a5e7f0',
      ],
    },
    dataZoom: {
      backgroundColor: 'rgba(0,0,0,0)',
      dataBackgroundColor: 'rgba(255,255,255,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#333',
      },
    },
    markPoint: {
      label: {
        color: '#eee',
      },
      emphasis: {
        label: {
          color: '#eee',
        },
      },
    },
  });

  registerTheme('dark', {
    color: [
      '#519163',
      '#69cc78',
      '#606f7f',
      '#89cbb3',
      '#235e21',
      '#6fa078',
    ],
    backgroundColor: '#00000000',
    textStyle: {},
    title: {
      textStyle: {
        color: '#ffffff',
      },
      subtextStyle: {
        color: '#dddddd',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '4',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: '4',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '6',
      symbol: 'circle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: '0',
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#fc97af',
        color0: 'transparent',
        borderColor: '#fc97af',
        borderColor0: '#87f7cf',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      lineStyle: {
        width: '1',
        color: '#ffffff',
      },
      symbolSize: '6',
      symbol: 'circle',
      smooth: true,
      color: [
        '#fc97af',
        '#87f7cf',
        '#f7f494',
        '#72ccff',
        '#f7c5a0',
        '#d4a4eb',
        '#d2f5a6',
        '#76f2f2',
      ],
      label: {
        color: '#293441',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
      },
      label: {
        color: '#893448',
      },
      emphasis: {
        itemStyle: {
          areaColor: 'rgba(255,178,72,1)',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
        label: {
          color: 'rgb(137,52,72)',
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
      },
      label: {
        color: '#893448',
      },
      emphasis: {
        itemStyle: {
          areaColor: 'rgba(255,178,72,1)',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
        label: {
          color: 'rgb(137,52,72)',
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(255,255,255,0.13)'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(255,255,255,0.13)'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(255,255,255,0.13)'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(255,255,255,0.13)'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        borderColor: '#999999',
      },
      emphasis: {
        iconStyle: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#87f7cf',
        width: 1,
      },
      itemStyle: {
        color: '#87f7cf',
        borderWidth: 1,
      },
      controlStyle: {
        color: '#87f7cf',
        borderColor: '#87f7cf',
        borderWidth: 0.5,
      },
      checkpointStyle: {
        color: '#fc97af',
        borderColor: '#fc97af',
      },
      label: {
        color: '#87f7cf',
      },
      emphasis: {
        itemStyle: {
          color: '#f7f494',
        },
        controlStyle: {
          color: '#87f7cf',
          borderColor: '#87f7cf',
          borderWidth: 0.5,
        },
        label: {
          color: '#87f7cf',
        },
      },
    },
    visualMap: {
      color: ['#fc97af', '#87f7cf'],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(114,204,255,1)',
      fillerColor: 'rgba(114,204,255,0.2)',
      handleColor: '#72ccff',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#293441',
      },
      emphasis: {
        label: {
          color: '#293441',
        },
      },
    },
  });
}
