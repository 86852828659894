import React, { useEffect } from 'react';
import uiTheme from 'utils/theming/ThirdPartyThemes';
import { Mode } from 'utils/theming/Themes';
import { register as registerEchartTheme } from 'utils/theming/charts/Theme';
import { CssVarsProvider, StyledEngineProvider, useColorScheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCookies } from 'react-cookie';

export function ModeEnforcer({ mode }: { mode: Mode }): null {
  const { setMode } = useColorScheme();

  const [cookies] = useCookies(['theme']);
  useEffect(() => {
    if (cookies.theme as 'light' | 'dark' | 'system') {
      setMode(cookies.theme as 'light' | 'dark' | 'system');
    } else {
      setMode(mode);
    }
  }, [mode, setMode, cookies.theme]);

  return null;
}

export default function ThemeLoader({
  children,
  defaultMode = 'system',
}: {
  children: React.ReactNode;
  defaultMode?: Mode;
}): JSX.Element {
  useEffect(() => {
    registerEchartTheme();
  }, []);

  const [cookies] = useCookies(['theme']);
  const selectedTheme = cookies.theme || defaultMode;

  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider
        disableTransitionOnChange
        theme={uiTheme}
        defaultMode={selectedTheme}
        modeStorageKey="app-appearance-mode"
      >
        <CssBaseline />
        {/* Pass the selected theme as the forceMode prop */}
        <ModeEnforcer mode={selectedTheme as 'light' | 'dark' | 'system'} />
        {children}
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}
