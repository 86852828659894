import {
  Button, Card, Chip,
  Stack, Typography, useColorScheme,
} from '@mui/joy';
import { RawApi } from 'api/openapi/generated/RawApi';
import errors from 'assets/errors.json';
import Illustration from 'components/Illustration';
import { useMobileMode } from 'components/Responsive';
import React from 'react';
import { IoHelp } from 'react-icons/io5';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState, store } from 'store/Store';
import { clearErrorStack } from 'store/slices/system';
import Restriction, { Privilege, Strategy } from 'utils/security/Restriction';

export type Status = number | RegExp;

export type Route = keyof typeof RawApi.endpoints | RegExp;

export type Display = 'modal' | 'page' | 'snackbar';

/**
 * The S.H.I.E.L.D. component (Strategic Hypertext Interface for Error Logging and Display) is a
 * component that displays on-demand error messages to the user when a request fails. It relies on
 * both the AuthListener and the SystemListener middlewares to function properly.
 */
export default function Shield({
  children,
  status = /^5\d{2}$/ || /^4\d{2}$/,
  route = /^\/.*/,
  display = 'page',
}: {
  children: React.ReactNode;
  /** The status code(s) that should trigger the shield. */
  status?: Status | Status[];
  /** The route(s) that the shield should apply to. */
  route?: Route | Route[];
  /** Whether or not the shield should be displayed. */
  display?: Display | ((error: NonNullable<RootState['system']['errorStack']>, route: keyof typeof RawApi.endpoints) => Display);
}) {
  const errorStack = useSelector((state: RootState) => state.system.errorStack);
  const mobile = useMobileMode();
  const { mode, systemMode } = useColorScheme();
  const darkMode = mode === 'dark' || systemMode === 'dark';

  // Convert the status and route props to arrays if they are not already.
  const statuses = Array.isArray(status) ? status : [status];
  const routes = Array.isArray(route) ? route : [route];
  const errorMatch = errorStack?.find((e) => {
    const routeMatch = routes.some((r) => {
      if (typeof r === 'string') return e.source === r;
      return r.test(e.source);
    });
    const statusMatch = statuses.some((s) => {
      if (typeof s === 'number') return e.status === s;
      return s.test(e.status?.toString() || '');
    });
    return routeMatch && statusMatch;
  });

  // Reset the error state when the user navigates to a new page.
  React.useEffect(() => {
    store.dispatch(clearErrorStack());
  }, []);

  if (
    errorStack === undefined
    || !errorMatch
  ) return children;

  return (
    <Stack
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: '20vh',
        paddingBottom: '10vh',
      }}
    >
      <Stack
        alignItems="center"
        gap={2}
        sx={{
          width: 'min(80%, 650px)',
        }}
      >
        <Stack
          gap={5}
          direction={mobile ? 'column' : 'row'}
          alignItems={mobile ? 'center' : 'start'}
          justifyContent="center"
        >
          <Illustration
            name="walking_outside"
            style={{
              width: 'min(80%, 200px)',
            }}
          />
          <Typography
            level="title-lg"
            fontWeight="sm"
            sx={{
              position: 'relative',
              fontSize: 'min(30vw, 150px)',
              fontFamily: "'Big Shoulders Inline Text', cursive",
              textDecoration: 'none',
              textColor: 'inherit',
            }}
            component="a"
            target="_blank"
            href={errorMatch.status ? `https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${errorMatch.status}` : ''}
          >
            {errorMatch.status || 'Oops'}
          </Typography>
        </Stack>
        <Stack p={2} alignItems="center">
          <Typography
            sx={{
              textAlign: 'center',
            }}
            fontWeight="sm"
            level={mobile ? 'h3' : 'h1'}
          >
            Sorry, we can&apos;t show you that.
          </Typography>
          {!('privilege' in errorMatch) ? (
            <Typography
              sx={{
                textAlign: 'center',
              }}
              textColor="text.tertiary"
              fontWeight="sm"
              level="title-md"
            >
              {errors[errorMatch.message as keyof typeof errors]?.dialog || errorMatch.message || 'Something went wrong. Please try again later.'}
            </Typography>
          ) : null}
          {'privilege' in errorMatch ? (
            <>
              <Restriction privilege={Privilege.KANOP_DEVELOPER} strategy={Strategy.HIDE} loader="none">
                <Stack
                  component={Card}
                  variant="outlined"
                  color="warning"
                  gap={1}
                  marginTop={3}
                  sx={{
                    width: 'min(100%, 400px)',
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                    }}
                    textColor="text.tertiary"
                    level="body-sm"
                  >
                    Authorization error encountered, expected the following privileges
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={1}>
                    {errorMatch.privilege.required.map((privilege) => (
                      <Chip size="sm" color="neutral" variant="soft" key={privilege}>
                        {privilege}
                      </Chip>
                    ))}
                  </Stack>
                  <Typography
                    sx={{
                      textAlign: 'center',
                    }}
                    textColor="text.tertiary"
                    level="body-sm"
                  >
                    Your privileges are currently set to
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={1}>
                    {errorMatch.privilege.actual.map((privilege) => (
                      <Chip size="sm" color="neutral" variant="soft" key={privilege}>
                        {privilege}
                      </Chip>
                    ))}
                  </Stack>
                </Stack>
              </Restriction>
              <Restriction
                privilege={{
                  name: 'Non technical',
                  privileges: [
                    Privilege.READER,
                    Privilege.CUSTOMER,
                  ],
                }}
                strategy={Strategy.HIDE}
                loader="none"
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                  }}
                  textColor="text.tertiary"
                  fontWeight="sm"
                  level="title-md"
                >
                  You don&apos;t have permission to view this page.
                </Typography>
              </Restriction>
            </>
          ) : null}
        </Stack>
        <Restriction privilege={Privilege.KANOP_ADMINISTRATOR} strategy={Strategy.HIDE} loader="none">
          <Card
            variant="outlined"
          >
            <Typography level="body-sm">
              Here&apos;s some more information about the issue:
            </Typography>
            <ReactJson
              src={errorMatch}
              theme={darkMode ? 'ashes' : 'bright:inverted'}
              iconStyle="square"
              style={{
                padding: '0.5rem',
                borderRadius: '0.5rem',
                overflow: 'auto',
              }}
            />
          </Card>
        </Restriction>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            color="neutral"
            component={Link}
            to="/"
          >
            Back to safety
          </Button>
          <Button
            color="danger"
            variant="outlined"
            onClick={() => store.dispatch(clearErrorStack())}
          >
            Dismiss
          </Button>
        </Stack>
        <Button
          variant="plain"
          color="neutral"
          size="sm"
          startDecorator={
            <IoHelp />
          }
          component="a"
          href="mailto:support@kanop.io"
        >
          Contact Kanop Support
        </Button>
      </Stack>
    </Stack>
  );
}
