// errorHandler.ts for Groundtruth plot and collection operations
import errors from 'assets/errors.json';

export default function getErrorMessage(errorKey: string): string {
  const error = (errors as any)[errorKey];
  if (error && error.dialog) {
    return error.dialog;
  }
  return 'Unknown error occurred'; // Default error message
}
