import { LoginLoginPostApiArg, RawApi, RegisterRegisterPostApiArg, ResetPasswordPasswordPatchApiArg } from 'api/openapi/generated/RawApi';
import { CustomApiArg } from 'types/Form';
export const KanopApiTagTypes = [
  'User',
  'ProjectList',
  'Project',
  'fileId',
  'Configuration',
  'Results',
  'DataRequest',
  'Run',
  'OrganizationList',
  'Organization',
  'OrganizationMembers',
  'OrganizationRoles',
];
  // Temporary fix for TS issue: "injectEndpoints"(fix) instead of "enhanceEndpoints"(former)
  const endpoints: Required<Parameters<typeof RawApi.injectEndpoints>[0]["endpoints"]> = {
  createNewRunProjectsProjectIdRunsPost: {
    invalidatesTags: ['Run'],
  },
  setProjectStatusProjectsProjectIdPatch: {
    invalidatesTags: ['Project'],
  },
  setRunStatusProjectsProjectIdRunsRunIdPatch: {
    invalidatesTags: ['Run'],
  },
  pingPingGet: {},
  getVersionVersionGet: {},
  getProjectReferencesReferencesGet: {},
  getProjectsGet: {
    providesTags: ['ProjectList'],
  },
  createProjectPost: {
    invalidatesTags: ['ProjectList'],
  },
  getProjectProjectIdGet: {
    providesTags: ['Project'],
  },
  deleteCustomerProjectProjectIdDelete: {
    invalidatesTags: ['ProjectList'],
  },
  patchCustomerProjectProjectIdPatch: {
    invalidatesTags: ['ProjectList'],
  },
  getPolygonsForProjectProjectIdPolygonsGet: {
    providesTags: ['Project', 'Configuration'],
  },
  deletePolygonProjectIdPolygonsDelete: {
    invalidatesTags: ['Project', 'fileId'],
  },
  createPolygonProjectIdPolygonsPost: {
    invalidatesTags: ['Project', 'Configuration'],
  },
  getAggregationLevelForProjectProjectIdAggregationLevelsGet: {
    providesTags: ['Project', 'Configuration'],
  },
  getAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGet: {
    providesTags: ['Project', 'Configuration'],
  },
  createForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPost:
    {
      invalidatesTags: ['Configuration'],
    },
  createAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPost:
    {
      invalidatesTags: ['Configuration'],
    },
  getConfigurationProjectIdConfigurationsGet: {
    providesTags: ['Configuration'],
  },
  getRequestsProjectIdRequestsGet: {
    providesTags: ['Project', 'DataRequest'],
  },
  createDataRequestProjectIdRequestsPost: {
    invalidatesTags: ['Project', 'DataRequest'],
  },
  getDataRequestProjectIdRequestsRequestIdGet: {
    providesTags: ['DataRequest', 'Run'],
  },
  getProjectTimeseriesEvolutionIndicatorsProjectIdEvolutionIndicatorsGet: {
    providesTags: ['Results'],
  },
  getProjectTimeseriesVariationIndicatorsProjectIdVariationIndicatorsGet: {
    providesTags: ['Results'],
  },
  getSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGet: {
    providesTags: ['DataRequest', 'Run'],
  },
  getProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGet: {
    providesTags: ['Project', 'DataRequest', 'Results'],
  },
  getAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGet: {
    providesTags: ['DataRequest', 'Run'],
  },
  downloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGet: {
    providesTags: ['Project', 'DataRequest', 'Results'],
  },
  getVariationGisFilesForRequestProjectIdRequestsRequestIdVariationGisFilesGet: {
    providesTags: ['Project', 'DataRequest', 'Results'],
  },
  downloadVariationGisFilesForRequestByNameProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGet:
    {
      providesTags: ['Project', 'DataRequest', 'Results'],
    },
  registerRegisterPost: {
    providesTags: ['User'],
    query: (queryArg: RegisterRegisterPostApiArg) => ({
      url: `/users/register`,
      method: "POST",
      body: new URLSearchParams(queryArg.bodyRegisterRegisterPost),
      formData: true,
    }),
  },
  readUsersMeMeGet: {
    providesTags: ['User'],
  },
  loginLoginPost: {
    invalidatesTags: ['User'],
    query: (queryArg: LoginLoginPostApiArg) => ({
      url: `/users/login`,
      method: "POST",
      body: new URLSearchParams(queryArg.bodyLoginLoginPost as unknown as string),
      formData: true,
    }),
  },
  logoutLogoutPut: {
    invalidatesTags: KanopApiTagTypes,
  },
  requestForgotPasswordTokenPasswordForgetPost: {},
  resetPasswordPasswordPatch: {
    invalidatesTags: ['User'],
    query: (queryArg: ResetPasswordPasswordPatchApiArg) => ({
      url: `/users/password`,
      method: "PATCH",
      body: new URLSearchParams(queryArg.bodyResetPasswordPasswordPatch),
      params: { token: queryArg.token },
      formData: true,
    }),
  },
  uploadGroundTruthsProjectIdFieldMeasurementsPost: {
  invalidatesTags: ['Project'],
  query: (queryArg: CustomApiArg) => ({
    url: `/projects/${queryArg.projectId}/fieldMeasurements`,
    method: "POST",
    body: queryArg.bodyUploadGroundTruthsProjectIdFieldMeasurementsPost.content,
    headers: { "accept-version": queryArg["accept-version"], },
    }),
  },
    getFieldMeasurementListProjectIdFieldMeasurementsGet: {
    providesTags: ['Project'],
  },
  getApplicationsForUserApplicationsGet: {
    providesTags: ['User'],
  },
  createApplicationForUserApplicationsPost: {
    invalidatesTags: ['User'],
  },
  getApplicationForClientIdApplicationsClientIdGet: {
    providesTags: ['User'],
  },
  deleteApplicationByClientIdApplicationsClientIdDelete: {
    invalidatesTags: ['User'],
  },
  updateApplicationByClientIdApplicationsClientIdPatch: {
    invalidatesTags: ['User'],
  },
  inviteUserToAppInviteesPost: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  inviteUserToAppInviteesOptions: {},
  getOrganizationsOrganizationsGet: {
    providesTags: ['OrganizationList'],
  },
  getOrganizationByIdOrganizationsOrganizationIdGet: {
    providesTags: ['Organization'],
  },
  getOrganizationByIdOrganizationsOrganizationIdOptions: {},
  getMembersByOrganizationIdOrganizationsOrganizationIdMembersGet: {
    providesTags: ['OrganizationMembers'],
  },
  deleteMemberOrganizationsOrganizationIdMembersDelete: {
    invalidatesTags: ['OrganizationMembers'],
  },
  deleteMemberOrganizationsOrganizationIdMembersOptions: {},
  getRolesByOrganizationIdOrganizationsOrganizationIdRolesGet: {
    providesTags: ['OrganizationRoles'],
  },
  getRolesByOrganizationIdOrganizationsOrganizationIdRolesOptions: {},
  inviteUserToOrganizationOrganizationsOrganizationIdInviteesPost: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  inviteUserToOrganizationOrganizationsOrganizationIdInviteesOptions: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  acceptInvitationOrganizationsOrganizationIdAcceptOptions: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  getVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGet: {},
  downloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGet:
    {},
  activateAccountConfirmGet: {},
  activateAccountConfirmOptions: {},
  createOrganizationOrganizationsPost: {
    invalidatesTags: ['OrganizationList'],
  },
  createOrganizationOrganizationsOptions: {},
  acceptInvitationOrganizationsOrganizationIdAcceptPatch: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  getInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGet: {
    providesTags: ['Organization', 'OrganizationMembers'],
  },
};

const KanopApi = RawApi.enhanceEndpoints({
  addTagTypes: KanopApiTagTypes,
  endpoints,
});

export default KanopApi;
