import React, { useState } from 'react';
import { Input } from '@mui/joy';

interface FormInputProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  fullWidth?: boolean;
  sx?: React.CSSProperties;
  type?: string;
  disabled?: boolean;
}

const TextInput = ({
  name,
  value,
  disabled,
  onChange,
  placeholder,
  fullWidth = true,
  sx,
  type,
}: FormInputProps) => {
  const maxDate = '2023-12-31';
  const [inputType, setInputType] = useState('text');

  // Function to display date picker when date input is in focus
  const handleFocus = () => {
    if (name === 'observationDate') {
      setInputType('date');
    }
  };

  // Function to display date placeholder when date input is out of focus
  const handleBlur = () => {
    if (name === 'observationDate') {
      setInputType('text');
    }
  };

  // Determine the input value based on the name and value props
  let inputValue = value;
  if (name !== 'observationDate' && name !== 'plotReference' && value === undefined) {
    inputValue = '';
  }

  return (
    <div className="form-group">
      <Input
        type={name === 'observationDate' ? inputType : type}
        name={name}
        disabled={disabled}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        slotProps={{
          input: {
            max: (() => {
              if (name === 'observationDate') {
                return maxDate;
              } if (name === 'canopyCover') {
                return 100;
              }
              return undefined;
            })(),
            min: (() => {
              if (name !== 'observationDate' && name !== 'plotReference') {
                return 0;
              }
              return undefined;
            }
            )(),
          },
        }}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth={fullWidth}
        required={name === 'plotReference' || name === 'observationDate'}
        sx={sx}
      />
    </div>
  );
};

export default TextInput;
