import {
  AnyAction, ListenerEffectAPI, ThunkDispatch, createListenerMiddleware, isAnyOf,
} from '@reduxjs/toolkit';
import KanopApi, { KanopApiTagTypes } from 'api/openapi/KanopApi';
import { RawApi } from 'api/openapi/generated/RawApi';
import TagManager from 'react-gtm-module';
import { clearStorage } from 'store/slices/storage';
import { clearSystem, setBearers } from 'store/slices/system';
import { SystemError } from './systemListenerMiddleware';

const {
  loginLoginPost,
  logoutLogoutPut,
  requestForgotPasswordTokenPasswordForgetPost,
  resetPasswordPasswordPatch,
  readUsersMeMeGet,
} = RawApi.endpoints;

export interface AuthError extends SystemError {
  privilege: {
    required: string[];
    actual: string[];
  }
}

export const clearSession = (
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>,
) => {
  // Clear the local storage if the credentials are invalid
  localStorage.removeItem('access-bearer');
  localStorage.removeItem('refresh-bearer');

  // Clear the system state
  listenerApi.dispatch(clearSystem());
  listenerApi.dispatch(clearStorage());

  // Invalidate all tags
  listenerApi.dispatch(KanopApi.util.invalidateTags(KanopApiTagTypes));
};

const authListenerMiddleware = createListenerMiddleware();

// Login listener
authListenerMiddleware.startListening({
  matcher: loginLoginPost.matchFulfilled,
  effect: (action, listenerApi) => {
    const { accessToken, refreshToken } = action.payload;

    // Set the bearers in the system state
    listenerApi.dispatch(setBearers({
      access: accessToken || '',
      refresh: refreshToken || '',
    }));

    // Set the bearers in the local storage
    localStorage.setItem('access-bearer', accessToken || '');
    localStorage.setItem('refresh-bearer', refreshToken || '');
  },
});

// Local storage removal listener
authListenerMiddleware.startListening({
  matcher: isAnyOf(
    logoutLogoutPut.matchFulfilled,
    requestForgotPasswordTokenPasswordForgetPost.matchFulfilled,
    resetPasswordPasswordPatch.matchFulfilled,
  ),
  effect: (_, listenerApi) => {
    clearSession(listenerApi);
  },
});

// User info fetch listener
authListenerMiddleware.startListening({
  matcher: readUsersMeMeGet.matchFulfilled,
  effect: (action, listenerApi) => {
    TagManager.dataLayer({
      dataLayer: {
        userEmail: action.payload.email,
      },
    });
  },
});

export default authListenerMiddleware;
