import {
  Stack, Typography,
} from '@mui/joy';
import Illustration from 'components/Illustration';
import React from 'react';
import ProceduralDialog from 'utils/ProceduralDialog';
import Title from 'utils/Title';

export default function NotFound() {
  const message = ProceduralDialog.generate('NotFound');

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 7,
      }}
    >
      <Title text="Not found" />
      <Illustration
        name="page_not_found"
        style={{
          width: 'min(80%, 600px)',
        }}
      />
      <Stack alignItems="center" gap={1}>
        <Typography level="h2">
          {message}
        </Typography>
        <Typography level="title-md" textColor="text.secondary" textAlign="center">
          Sorry, we couldn&apos;t find the page you&apos;re looking for...
        </Typography>
      </Stack>
    </Stack>
  );
}
