import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { RawApi } from 'api/openapi/generated/RawApi';
import { pushError } from 'store/slices/system';
import { clearSession } from './authListenerMiddleware';

export interface SystemError {
  message: string;
  status: number;
  source: string;
}

export const errorSignatures: Record<
  string,
  {
    status: number;
    message: RegExp;
  }
> = {
  invalidCredentials: {
    status: 401,
    message: /Invalid authentication credentials/,
  },
  invalidPrivileges: {
    status: 401,
    message: /require \[(.*)\] found \[(.*)\]/,
  },
};

const signatureMatch = (
  error: {
    originalStatus: number;
    status: number | string;
    data: {
      detail: string;
    };
  },
  signature: keyof typeof errorSignatures,
) => {
  const { status, message } = errorSignatures[signature];

  const errorStatus = typeof error.status === 'number' ? error.status : error.originalStatus;

  return (
    errorStatus === status
    && (typeof message === 'string'
      ? error?.data?.detail === message
      : message.test(error?.data?.detail))
  );
};

const systemListenerMiddleware = createListenerMiddleware();

// Fetch failure listener
systemListenerMiddleware.startListening({
  matcher: isAnyOf(...Object.values(RawApi.endpoints).map((endpoint) => endpoint.matchRejected)),
  effect: (action, listenerApi) => {
    const status = typeof action.payload?.status === 'number'
      ? action.payload?.status
      : action.payload?.originalStatus;
    const detail = typeof action.payload?.data === 'string'
      ? action.payload?.data
      : action.payload?.data?.detail || undefined;

    if (!action.payload) {
      return;
    }

    if (signatureMatch(action.payload, 'invalidCredentials')) {
      clearSession(listenerApi);
    } else if (signatureMatch(action.payload, 'invalidPrivileges')) {
      // set the error as an AuthError
      const [, required, actual] = detail.match(errorSignatures.invalidPrivileges.message) || [];

      listenerApi.dispatch(
        pushError({
          message: detail,
          status,
          source: action.meta.arg.endpointName,
          privilege: {
            required: required.split(', '),
            actual: actual.split(', '),
          },
        }),
      );
    } else {
      // Otherwise, set the error as a SystemError
      listenerApi.dispatch(
        pushError({
          message: detail,
          source: action.meta.arg.endpointName,
          status,
        }),
      );
    }
  },
});

export default systemListenerMiddleware;
