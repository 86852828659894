import Dialogs from 'assets/dialogs.json';

export type DialogType = keyof typeof Dialogs;

export type DialogDataBase = {
  [key in DialogType]: string[];
};

/**
 * The ProceduralDialog class is used to generate random messages for the user.
 */
export default class ProceduralDialog {
  /**
   * A function that generates a random message for the user.
   * @param type the type of dialog to generate
   * @returns a random message from the dialog
   */
  static generate(type: DialogType) {
    const db: string[] = (Dialogs as unknown as DialogDataBase)[type];
    // generate random index
    const index = Math.floor(Math.random() * db.length);
    return db[index];
  }
}
