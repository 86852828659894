import {
  Alert,
  Button,
  Divider, FormControl, FormLabel, Input, Stack, Typography,
} from '@mui/joy';
import KanopApi from 'api/openapi/KanopApi';
import errors from 'assets/errors.json';
import React, { useState } from 'react';
import {
  TbAlignLeft, TbAt,
  TbInfoSquareRounded, TbLink, TbLocation,
} from 'react-icons/tb';
import { Link, Navigate } from 'react-router-dom';
import Title from 'utils/Title';

export default function NewOrganization(): JSX.Element {
  const [label, setLabel] = useState('');
  const [website, setWebsite] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');

  const [createOrganization, {
    isLoading: isCreating,
    error: createError,
    isSuccess: createSuccess,
  }] = KanopApi.useCreateOrganizationOrganizationsPostMutation();

  return (
    <Stack sx={{
      p: 2,
      paddingTop: 10,
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
    >
      <Title text="New organization" />
      {createSuccess && (
        <Navigate to="/" />
      )}
      <Stack
        sx={{
          width: 'min(100%, 600px)',
          gap: 2,
        }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();

          createOrganization({
            organizationPayload: {
              label,
              website,
              location,
              description,
            },
          });
        }}
      >
        <Stack>
          <Typography level="h4">
            New organization
          </Typography>
          <Typography level="body-md" textColor="text.secondary">
            Create a new organization to manage your projects.
          </Typography>
        </Stack>
        <Divider />
        <Stack gap={2}>
          <FormControl required>
            <FormLabel>
              Organization name
            </FormLabel>
            <Input
              autoComplete="off"
              startDecorator={<TbAt />}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Organization description
            </FormLabel>
            <Input
              autoComplete="off"
              startDecorator={<TbAlignLeft />}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </FormControl>
          <Divider />
          <FormControl>
            <FormLabel>
              Organization website
            </FormLabel>
            <Input
              type="url"
              startDecorator={<TbLink />}
              autoComplete="off"
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Organization location
            </FormLabel>
            <Input
              autoComplete="off"
              startDecorator={<TbLocation />}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            />
          </FormControl>
        </Stack>
        {createError && 'data' in createError && (
          <Alert
            color="danger"
            startDecorator={(
              <TbInfoSquareRounded />
          )}
          >
            Organization could not be created:
            {' '}
            {errors[(createError.data as {detail?: string})?.detail as keyof typeof errors]?.dialog || 'Unknown error'}
          </Alert>
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            marginTop: 2,
          }}
        >
          <Button component={Link} to="/" variant="plain" color="neutral">
            Cancel
          </Button>
          <Button
            type="submit"
            loading={isCreating}
          >
            Create organization
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
