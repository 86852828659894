/* eslint-disable import/prefer-default-export */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RawApi } from 'api/openapi/generated/RawApi';
import { RootState } from 'store/Store';

/**
 * This is a workaround for the fact that the generated API does not take into account the
 * content-type of the request. This is a temporary solution until redux fixed this issue.
 * @temporary
 */
const contentType: Partial<Record<keyof typeof RawApi.endpoints, string>> = {
  loginLoginPost: 'application/x-www-form-urlencoded',
  registerRegisterPost: 'application/x-www-form-urlencoded',
  resetPasswordPasswordPatch: 'application/x-www-form-urlencoded',
};

/**
 * An empty template for creating new APIs
 */
export const emptyApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders(headers, listenerApi) {
      const { bearers } = (listenerApi.getState() as RootState).system;
      if (bearers?.access) {
        headers.set('authorization', `Bearer ${bearers.access}`);
      }

      headers.set('Accept-version', 'v1');
      return headers;
    },
  }),
  reducerPath: 'api',
  endpoints: () => ({}),
});
