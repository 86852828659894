import { useColorScheme } from '@mui/joy';
import illustrations from 'assets/illustrations/illustations.json';
import React from 'react';

const svgs = require.context('assets/illustrations', true, /\.svg$/);

export interface IllustrationProps extends React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> {
  name: keyof typeof illustrations;
}

/**
 * An illustration component that loads SVGs from the assets/illustrations folder
 * and applies a dark mode filter if the user is in dark mode.
 */
export default function Illustration({ name, ...props }: IllustrationProps) {
  const { mode, systemMode } = useColorScheme();

  const darkMode = mode === 'dark' || systemMode === 'dark';

  return (
    <img
      src={svgs(`./${illustrations[name]}`)}
      alt={name}
      {...props}
      style={{
        ...props.style,
        filter: (darkMode) ? 'invert(100%) hue-rotate(180deg) saturate(110%)'
          : undefined,
        mixBlendMode: (darkMode) ? 'exclusion' : undefined,
      }}
    />
  );
}
