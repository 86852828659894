import { Card, Sheet } from '@mui/joy';
import wallpaper from 'assets/banners/banner_1.jpg';
import { Default, Mobile } from 'components/Responsive';
import React from 'react';

export default function Prompt({ children }: { children: React.ReactNode }) {
  return (

    <>
      <Default>
        <Sheet
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={wallpaper}
            alt="wallpaper"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              objectFit: 'cover',
              minWidth: '105%',
              minHeight: '105%',
              filter: 'blur(10px) opacity(0.5)',
            }}
          />
          <Card
            variant="outlined"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              dropShadow: 'md',
              width: '90%',
              maxWidth: '300px',
              height: 'fit-content',
              maxHeight: '90%',
              overflow: 'auto',
              p: 4,
            }}
          >
            {children}
          </Card>
        </Sheet>
      </Default>
      <Mobile>
        <Sheet sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'auto',
          p: 5,
        }}
        >
          {children}
        </Sheet>
      </Mobile>
    </>
  );
}
