/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AuthError } from 'store/middleware/authListenerMiddleware';
import { SystemError } from 'store/middleware/systemListenerMiddleware';
import { ElementOf } from 'types/Utils';

export interface SystemState {
  errorStack?: (SystemError | AuthError)[];
  bearers?: {
    access: string;
    refresh: string;
  };
}

const computeInitialState: () => SystemState = () => ({
  errorStack: undefined,
  bearers: {
    access: localStorage.getItem('access-bearer') || '',
    refresh: localStorage.getItem('refresh-bearer') || '',
  },
});

export const systemSlice = createSlice({
  name: 'system',
  initialState: computeInitialState(),
  reducers: {
    pushError: (state, action: PayloadAction<ElementOf<SystemState['errorStack']>>) => ({
      ...state,
      errorStack: [...(state.errorStack || []), action.payload],
    }),
    clearErrorStack: (state) => ({
      ...state,
      errorStack: undefined,
    }),
    setBearers: (state, action: PayloadAction<SystemState['bearers']>) => ({
      ...state,
      bearers: action.payload,
    }),
    clearSystem: () => computeInitialState(),
  },
});

export const {
  pushError, clearErrorStack, setBearers, clearSystem,
} = systemSlice.actions;

export default systemSlice.reducer;
